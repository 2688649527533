import { RouteInfo } from './vertical-menu.metadata';

//Sidebar menu Routes and data
export const ROUTES: RouteInfo[] = [
  {
    path: 'eventos',
    title: 'Eventos',
    icon: 'ft-calendar',
    class: '',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    submenu: [],
  },
  {
    path: 'empresas',
    title: 'Empresas',
    icon: 'ft-archive',
    class: '',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    submenu: [],
  },
];
