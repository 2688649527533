import { Routes, RouterModule } from '@angular/router';

// Route for content layout with sidebar, navbar and footer.

export const Full_ROUTES: Routes = [
  {
    path: 'empresas',
    loadChildren: () =>
      import('@app/empresas/empresas.module').then(m => m.EmpresasModule),
  },
  {
    path: 'eventos',
    loadChildren: () =>
      import('@app/eventos/eventos.module').then(m => m.EventosModule),
  },
];
