import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { User } from './user';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as globals from '@shared/data/globals';
import { map } from 'rxjs/operators';
import {
  ResponseDefault,
  ResponseDefaultSingle,
  SingleData,
} from '../models/requests';

@Injectable()
export class AuthService {
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;

  constructor(public router: Router, public http: HttpClient) {
    this.currentUserSubject = new BehaviorSubject(
      JSON.parse(localStorage.getItem('currentUser'))
    );
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get headers() {
    return new HttpHeaders({
      Authorization: `Bearer ${this.currentUserValue.token}`,
    });
  }

  public get apiUrl() {
    return globals.API_URL + '/api/adm';
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  public post(path: string, body: any = {}) {
    return this.http.post(`${this.apiUrl}/${path}`, body, {
      headers: this.headers,
    });
  }

  login(email, senha) {
    return this.http.post(`${this.apiUrl}/login`, { email, senha }).pipe(
      map((res: ResponseDefaultSingle<SingleData<User | any>>) => {
        const user = res.data.original;
        if (!user.error) {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          localStorage.setItem('currentUser', JSON.stringify(user));
          this.currentUserSubject.next(user);
          return user;
        }
        throw new Error('Usuário inválido');
      })
    );
  }

  logout() {
    localStorage.clear();
    this.router.navigate(['pages/login']);
  }

  isAuthenticated() {
    if (this.currentUserValue) {
      // authorised so return true
      return true;
    }
  }

  solicitarTrocaSenha(email: string) {
    const formData = new FormData();
    formData.append('email', email);

    return this.http.post(`${this.apiUrl}/reset/request`, formData);
  }
}
